
import { computed, defineComponent, ref } from 'vue';
import { lostelkUrl } from '../global';
import ValidateInput, { RulesProp } from '../components/form/ValidateInput.vue';
import ValidateForm from '../components/form/ValidateForm.vue';
import createTooltip from '../components/globalFun/createTooltip';
import axios from 'axios';
import store from '../store';

export default defineComponent({
  name: 'Register',
  components: {
    ValidateInput,
    ValidateForm
  },
  props: {
    registeredImg: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const userNameVal = ref('');
    const passwordVal = ref('');
    const userLastNameVal = ref('');
    const userFirstNameVal = ref('');
    const userEmailVal = ref('');
    const affirmPasswordVal = ref('');
    const userNameRule: RulesProp = [
      { type: 'null', message: 'The user name cannot be empty' },
      {
        type: 'userName',
        message:
          'limited to letters, digits, underscores and up to 20 characters'
      }
    ];
    const passwordRule: RulesProp = [
      { type: 'null', message: 'The password cannot be empty' },
      {
        type: 'password',
        message: 'The password must be between 6 and 16 characters long'
      }
    ];
    const userLastNameRule: RulesProp = [
      { type: 'userLastName', message: 'Incorrect name format' }
    ];
    const userFirstNameRule: RulesProp = [
      { type: 'userfirstName', message: 'Incorrect name format' }
    ];
    const userEmailRule: RulesProp = [
      { type: 'null', message: 'The email address cannot be empty' },
      { type: 'userEmail', message: 'Email format error' }
    ];
    const affirmPasswordRule: RulesProp = [
      { type: 'null', message: 'Confirm password cannot be empty' },
      {
        type: 'custom',
        validator: () => {
          return passwordVal.value === affirmPasswordVal.value;
        },
        message: 'Different passwords'
      }
    ];

    const onFormSubmit = (result: boolean) => {
      if (result) {
        const userRegisterData = {
          email: userEmailVal.value,
          name: userNameVal.value,
          password: passwordVal.value
        };
        axios.post('/users', userRegisterData).then(() => {
          createTooltip(
            'Registration is successful, the activation link has been sent to your email. Please activate your account within 30 minutes ~',
            'default',
            null
          );
          // setTimeout(() => {
          //   router.push('/login');
          // }, 1000);
        });
      } else {
        // console.log('不通过');
      }
    };

    /**
     * 获取图片为横图还是纵图
     */
    const registeredImgArrange = computed(() => props.registeredImg);

    /**
     * 获取精选恒横图/纵图,以及随机数组下标
     */
    const transverse = computed(() => store.state.veryGoodsTransverseList);
    const transverseRandom = ref(
      Math.floor(Math.random() * transverse.value.length)
    );
    const longitudinal = computed(() => store.state.veryGoodsLongitudinalList);
    const longitudinalRandom = ref(
      Math.floor(Math.random() * longitudinal.value.length)
    );

    return {
      lostelkUrl,
      transverse,
      transverseRandom,
      longitudinal,
      longitudinalRandom,
      userNameRule,
      userNameVal,
      passwordVal,
      passwordRule,
      onFormSubmit,
      userLastNameVal,
      userLastNameRule,
      userFirstNameVal,
      userFirstNameRule,
      userEmailVal,
      userEmailRule,
      affirmPasswordVal,
      affirmPasswordRule,
      registeredImgArrange
    };
  }
});
